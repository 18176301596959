var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-footer', {
    attrs: {
      "padless": ""
    }
  }, [_c('v-card', {
    staticClass: "footer-container",
    attrs: {
      "color": "primary",
      "flat": "",
      "min-width": "100%",
      "tile": ""
    }
  }, [_c('v-card-text', {
    staticClass: "d-flex justify-center"
  }, [_c('v-sheet', {
    staticClass: "d-flex align-center justify-space-between pt-4",
    attrs: {
      "color": "transparent",
      "max-width": "24rem",
      "width": "100%"
    }
  }, _vm._l(_vm.socialIcons, function (icon) {
    return _c('v-btn', {
      key: icon.icon,
      staticClass: "white--text",
      attrs: {
        "href": icon.href,
        "large": "",
        "target": "_blank",
        "title": icon.title || '',
        "icon": ""
      }
    }, [_c('v-icon', {
      attrs: {
        "large": ""
      }
    }, [_vm._v(" " + _vm._s(icon.icon) + " ")])], 1);
  }), 1)], 1), _c('v-card-text', {
    staticClass: "secondary--text my-8"
  }, [_c('v-row', {
    attrs: {
      "align": "start",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "cols": "12",
      "md": "4",
      "lg": "3",
      "fill-height": ""
    }
  }, [_c('v-card', {
    staticClass: "info-card secondary--text",
    attrs: {
      "color": "transparent",
      "flat": "",
      "tile": ""
    }
  }, [_c('v-card-title', {
    staticClass: "justify-start"
  }, [_c('v-icon', {
    attrs: {
      "color": "secondary",
      "left": ""
    }
  }, [_vm._v("mdi-phone-classic")]), _vm._v("CONTACT US ")], 1), _c('v-card-text', {
    staticClass: "secondary--text text-left",
    attrs: {
      "color": "transparent"
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex align-center secondary--text",
    attrs: {
      "color": "transparent"
    }
  }, [_c('strong', [_vm._v(" PHONE:")]), _vm._v(" "), _c('a', {
    staticClass: "contact-link secondary--text ml-2",
    attrs: {
      "href": "tel:+6315819200"
    }
  }, [_vm._v("(631) 581-9200")])]), _c('v-sheet', {
    staticClass: "d-flex align-center secondary--text",
    attrs: {
      "color": "transparent"
    }
  }, [_c('strong', [_vm._v("E-MAIL:")]), _vm._v(" "), _c('a', {
    staticClass: "contact-link secondary--text ml-2",
    attrs: {
      "href": "mailto:help@eipl.org"
    }
  }, [_vm._v("help@eipl.org")])])], 1)], 1)], 1), _c('v-col', {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "cols": "12",
      "md": "4",
      "lg": "3",
      "fill-height": ""
    }
  }, [_c('v-card', {
    staticClass: "info-card secondary--text",
    attrs: {
      "color": "transparent",
      "flat": "",
      "tile": ""
    }
  }, [_c('v-card-title', {
    staticClass: "justify-start"
  }, [_c('v-icon', {
    attrs: {
      "color": "secondary",
      "left": ""
    }
  }, [_vm._v("mdi-map-marker")]), _vm._v(" FIND US ")], 1), _c('v-card-text', {
    staticClass: "secondary--text text-left",
    attrs: {
      "color": "transparent"
    }
  }, [_c('p', {
    staticClass: "text=left ma-0"
  }, [_vm._v("381 East Main Street")]), _c('p', {
    staticClass: "text=left ma-0"
  }, [_vm._v("East Islip, NY 11730")]), _c('a', {
    attrs: {
      "href": "https://www.google.com/maps/dir//East+Islip+Public+Library,+East+Main+Street,+East+Islip,+NY/@40.7358185,-73.2083772,13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x89e8338949a41f37:0x60d81b56a4cb6efc!2m2!1d-73.1733617!2d40.7357697",
      "rel": "no-referrer",
      "target": "_blank"
    }
  }, [_c('v-img', {
    staticClass: "rounded mt-2",
    attrs: {
      "alt": "google map location of building",
      "cover": "",
      "height": "auto",
      "max-width": "300px",
      "width": "100%",
      "position": "center center",
      "src": "/images/location-map.png"
    }
  })], 1)])], 1)], 1), _c('v-col', {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "cols": "12",
      "md": "4",
      "lg": "3",
      "fill-height": ""
    }
  }, [_c('v-card', {
    staticClass: "info-card secondary--text",
    attrs: {
      "color": "transparent",
      "flat": "",
      "tile": ""
    }
  }, [_c('v-card-title', {
    staticClass: "justify-start"
  }, [_c('v-icon', {
    attrs: {
      "color": "secondary",
      "left": ""
    }
  }, [_vm._v("mdi-clock")]), _vm._v(" VISIT US ")], 1), _c('v-card-text', {
    staticClass: "d-flex align-center justify-start secondary--text text-left",
    attrs: {
      "color": "transparent"
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex flex-shrink-1 flex-column secondary--text",
    attrs: {
      "color": "transparent",
      "width": "150px"
    }
  }, [_c('p', {
    staticClass: "font-weight-bold ma-0"
  }, [_vm._v("MONDAY:")]), _c('p', {
    staticClass: "font-weight-bold ma-0"
  }, [_vm._v("TUESDAY:")]), _c('p', {
    staticClass: "font-weight-bold ma-0"
  }, [_vm._v("WEDNESDAY:")]), _c('p', {
    staticClass: "font-weight-bold ma-0"
  }, [_vm._v("THURSDAY:")]), _c('p', {
    staticClass: "font-weight-bold ma-0"
  }, [_vm._v("FRIDAY:")]), _c('p', {
    staticClass: "font-weight-bold ma-0"
  }, [_vm._v("SATURDAY:")]), _c('p', {
    staticClass: "font-weight-bold ma-0"
  }, [_vm._v("SUNDAY* :")]), _c('p', {
    staticClass: "font-weight-bold ma-0"
  }, [_vm._v("*(Oct. - Apr.)")])]), _c('v-sheet', {
    staticClass: "d-flex flex-column secondary--text text-center text-no-wrap",
    attrs: {
      "color": "transparent"
    }
  }, [_c('p', {
    staticClass: "ma-0"
  }, [_vm._v("9:00 AM - 9:00 PM")]), _c('p', {
    staticClass: "ma-0"
  }, [_vm._v("9:00 AM - 9:00 PM")]), _c('p', {
    staticClass: "ma-0"
  }, [_vm._v("9:00 AM - 9:00 PM")]), _c('p', {
    staticClass: "ma-0"
  }, [_vm._v("9:00 AM - 9:00 PM")]), _c('p', {
    staticClass: "ma-0"
  }, [_vm._v("9:00 AM - 6:00 PM")]), _c('p', {
    staticClass: "ma-0"
  }, [_vm._v("9:00 AM - 5:00 PM")]), _c('p', {
    staticClass: "ma-0"
  }, [_vm._v("1:00 PM - 5:00 PM")]), _c('p', {
    staticClass: "ma-0"
  }, [_vm._v(" ")])])], 1)], 1)], 1)], 1)], 1), _c('v-card-text', {
    staticClass: "building-image-container pa-0"
  }, [_c('v-img', {
    staticClass: "building-image",
    attrs: {
      "cover": "",
      "height": "auto",
      "max-width": "100%",
      "min-height": "200px",
      "min-width": "200px",
      "position": "left top",
      "width": "100%",
      "src": "/images/building-angle-2-bg.png"
    }
  }), _c('p', {
    staticClass: "copyright-text secondary--text text-center font-weight-black mb-0"
  }, [_vm._v(_vm._s(("© " + (new Date().getFullYear()))) + " EIPL.ORG")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }