<script>
  export default {
    name: 'nav-breadcrumbs',
    props: {
      show: {
        type: Boolean,
        default: () => false,
      },
    },
    data: () => ({}),
    computed: {
      breadCrumbItems() {
        const routeOptions = {
          home: {
            icon: 'mdi-home',
            text: 'Home',
          },
          kids: {
            text: 'Kids Corner',
          },
        }
        let breadCrumbs = []
        let path = '/'
        let text = ''
        const cleanUrl = this.$route.fullPath.replaceAll('//', '/').split('?')
        const urlSegments = cleanUrl[0].split('/')
        urlSegments.forEach((pathItem, idx) => {
          path += `${urlSegments.length > 2 && idx === urlSegments.length - 1 ? '/' : ''}${pathItem}`
          text = pathItem === '' ? 'Home' : pathItem
          breadCrumbs.push({
            text: routeOptions[text.toLowerCase()] ? routeOptions[text.toLowerCase()]['text'] : text,
            href: path,
            icon: routeOptions[text.toLowerCase()] ? routeOptions[text.toLowerCase()].icon : null,
          })
        })
        return breadCrumbs
      },
    },
    methods: {},
    created() {},
  }
</script>

<template>
  <v-breadcrumbs v-if="show" :items="breadCrumbItems" active-class="breadCrumbActive" color="secondary" class="d-flex align-center secondary--text font-weight-bold px-0">
    <template v-slot:divider>
      <v-icon color="secondary" class="px-0">mdi-chevron-right</v-icon>
    </template>
    <template v-slot:item="props">
      <router-link
        exact
        :to="props.item.href"
        class="breadCrumbs secondary--text font-weight-bold"
        :style="props.item.href === $route.fullPath ? { 'text-decoration': 'underline' } : ''"
      >
        <v-icon v-if="props.item.icon" color="secondary" small class="">{{ props.item.icon }}</v-icon>

        {{ props.item.text.toUpperCase() }}</router-link
      >
    </template>
  </v-breadcrumbs>
</template>

<style scoped>
  .breadCrumbs {
    text-decoration: none;
  }

  >>> .v-breadcrumbs__divider {
    padding: 0 !important;
  }
</style>
