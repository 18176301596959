<script>
  import AlertMessage from '@/components/alert-message.vue'
  import FooterMain from '@/components/footer-main.vue'
  import HeaderMain from '@/components/header-main.vue'
  import SearchSheet from '@/components/search-sheet.vue'
  import api from '@/utils/api.js'
  export default {
    name: 'App',
    components: { AlertMessage, FooterMain, HeaderMain, SearchSheet },
    data: () => ({
      alertVisible: false,
      scrollTopOptions: {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic',
      },
      scrollTopBtn: false,
      scrollTopTarget: 0,
      // snackbar: {
      //   color: 'primary',
      //   show: false,
      //   text: '',
      // },
    }),
    computed: {
      rt() {
        return this.$router
      },
      v() {
        return this.$vuetify
      },
    },
    methods: {
      onScroll(e) {
        this.scrollTopBtn = e?.srcElement?.scrollingElement?.scrollTop > 500
      },
      // onSnackBar({ color = 'primary', text = '' }) {
      //   this.snackbar = {
      //     color,
      //     show: true,
      //     text,
      //   }
      // },
    },
    async created() {
      const result = await api.get('home')
      console.log(result)
    },
  }
</script>
<template>
  <v-app>
    <header-main :alertVisible="alertVisible"></header-main>
    <v-main v-scroll="onScroll" class="main">
      <alert-message :showAlert="alertVisible"></alert-message>
      <router-view> </router-view>
      <search-sheet />
    </v-main>
    <!-- SNACKBAR -->
    <!-- <v-snackbar v-model="snackbar.show" app :color="snackbar.color" tile top>
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbar.show = false">
          <v-icon color="secondary">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar> -->
    <!-- SCROLL TO TOP BUTTON-->
    <v-fab-transition>
      <v-btn app bottom color="primary" fab fixed out right v-show="scrollTopBtn" @click="$vuetify.goTo(scrollTopTarget, scrollTopOptions)" class="btn-scroll-top">
        <v-icon color="secondary">mdi-chevron-double-up</v-icon>
      </v-btn>
    </v-fab-transition>
    <footer-main></footer-main>
  </v-app>
</template>

<style>
  main {
    background-color: #f5f3f0;
  }

  /* GLOBAL STYLES HERE */
  .cursor-pointer {
    cursor: pointer;
  }

  .full-width {
    width: 100%;
  }
  d .row {
    margin: 0 !important;
  }

  /* used as the base container on most pages */
  .main-container {
    position: relative;
    height: 100%;
    width: 100%;
    min-width: 100%;
    padding: 0px;
    padding-bottom: 69px;
  }

  /* APP  */
  .main {
    /* height: 100%; */
    min-height: 100vh;
    margin: 0;
  }

  .btn-scroll-top {
    z-index: 5;
  }
</style>
