<script>
  export default {
    name: 'hours-tile',
  }
</script>
<template>
  <v-card active-class="active-override" color="transparent" flat hover :link="false" to="/about" class="d-flex align-center secondary--text">
    <v-tooltip color="primary" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on" color="success" large left>mdi-home-clock</v-icon>
      </template>
      <v-sheet v-if="$vuetify.breakpoint.xs" color="transparent" class="d-flex flex-column secondary--text">
        <span class="font-weight-bold text-body-1 text-no-wrap">We are open!</span>
        <span class="text-subtitle-2 text-no-wrap"> 9:00 AM - 9:00 PM</span>
      </v-sheet>
    </v-tooltip>

    <v-sheet v-if="$vuetify.breakpoint.smAndUp" color="transparent" class="d-flex flex-column secondary--text">
      <span class="font-weight-bold text-body-1 text-no-wrap">We are open!</span>
      <span class="text-subtitle-2 text-no-wrap"> 9:00 AM - 9:00 PM</span>
    </v-sheet>
  </v-card>
</template>

<style></style>
