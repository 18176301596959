<script>
  import { mapState } from 'vuex'
  export default {
    name: 'search-form',
    props: {
      showOptions: {
        type: Boolean,
        default: () => false,
      },
    },
    data: () => ({
      searchLoading: false,
      searchTypeOptions: {
        catalog: [
          {
            label: 'Author',
            urlType: 'X',
            value: 'author',
          },
          {
            label: 'Keyword',
            urlType: 'X',
            value: 'keyword',
          },
          {
            label: 'Subject',
            urlType: 'd',
            value: 'subject',
          },
          {
            label: 'Title',
            urlType: 't',
            value: 'title',
          },
        ],
        website: [
          {
            label: 'Keyword',
            urlType: '',
            value: 'keyword',
          },
          {
            label: 'Program',
            urlType: '',
            value: 'program',
          },
          {
            label: 'Resource',
            urlType: '',
            value: 'resource',
          },
        ],
      },
    }),
    computed: {
      ...mapState({
        searchInput: state => state.searchInput,
        searchScope: state => state.searchScope,
        searchType: state => state.searchType,
      }),
    },
    methods: {
      fixRadioLabel(text) {
        return text
      },
      onSearchScopeChange(scope) {
        const defaultTopic = 'keyword'
        if (!this?.searchTypeOptions?.[scope]?.includes(this.searchType)) this.$store.dispatch('setSearchType', defaultTopic)
        this.$store.dispatch('setSearchScope', scope)
      },
      searchSubmit() {
        this.searchLoading = true
        // if (!this.searchSheetShow) this.$store.dispatch('toggleSearchSheet')
        if (this.searchScope === 'catalog') {
          this.searchCatalog()
        }

        return
      },
      searchCatalog() {
        // const SEARCH_URL = 'https://search.livebrary.com/search/?searchScope=X&SORT=D&searcharg=[PLACEHOLDER]&searchscope=20'
        const DEFAULT_TYPE = 'X'
        const searchTermFixed = encodeURI(this.searchInput.replaceAll(' ', '+'))
        const searchScope = '20' //May include option to for children's, 21
        const searchType = this.searchTypeOptions?.[this.searchScope]?.find(t => t.value === this.searchType)?.urlType || DEFAULT_TYPE
        //simple search (keyword)
        //https://search.livebrary.com/search/?searchtype=X&SORT=D&searcharg=king&searchscope=20

        //title (dark tower)
        // https://search.livebrary.com/search/?searchtype=t&SORT=D&searcharg=dark+tower&searchscope=20

        //author (king, stephen)
        //https://search.livebrary.com/search/?searchtype=X&SORT=D&searcharg=king%2C+stephen&searchscope=20

        //subject
        // https://search.livebrary.com/search/?searchtype=d&SORT=D&searcharg=fantasy&searchscope=20
        const url = `https://search.livebrary.com/search/?searchtype=${searchType || DEFAULT_TYPE}&SORT=D&searcharg=${searchTermFixed}&searchscope=${searchScope}`
        setTimeout(() => {
          window.open(url)
          this.searchLoading = false
        }, 500)
      },
    },
  }
</script>
<template>
  <v-card color="transparent" flat tile class="search-card full-width">
    <v-card-text class="pa-0">
      <div class="text-xs-center">
        <!-- <h4 class="title">SEARCH</h4> -->
      </div>
      <form @submit.prevent="searchSubmit">
        <v-text-field
          :label="`Search our ${searchScope}...`"
          :hint="!showOptions ? '' : searchScope === 'catalog' ? 'Search Books, Movies, Music, etc' : 'Search Events, Programs, Resources, etc..'"
          clearable
          color="primary"
          outlined
          persistent-hint
          required
          rounded
          solo
          type="text"
          :value="searchInput"
          class="primary--text"
          @click:append-outer="searchSubmit"
          @input="$store.dispatch('setSearchInput', $event)"
        >
          <!-- SEARCH SCOPE DROPDOWN MENU -->
          <template v-if="!showOptions" v-slot:prepend-inner>
            <v-menu bottom rounded>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark rounded v-bind="attrs" v-on="on" class="ml-n4 pr-2"> {{ searchScope.toUpperCase() }} <v-icon>mdi-menu-down</v-icon> </v-btn>
              </template>
              <v-list dense>
                <v-list-item @click="searchScope = 'catalog'">
                  <v-list-item-title class="primary--text"> <v-icon color="primary" left>mdi-bookshelf</v-icon> CATALOG</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item @click="searchScope = 'website'">
                  <v-list-item-title class="primary--text"><v-icon color="primary" left>mdi-web</v-icon>WEBSITE</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <!-- SUBMIT BUTTON -->
          <template v-slot:append>
            <v-fade-transition leave-absolute>
              <v-btn color="primary" fab :loading="searchLoading" role="button" small type="submit" class="mr-n4">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </v-fade-transition>
          </template>
        </v-text-field>
      </form>
      <!-- append-outer-icon="mdi-magnify" -->
      <v-sheet v-if="showOptions" color="transparent" class="d-flex flex-wrap text-xs-left pa-4">
        <v-sheet color="transparent" class="text-xs-left pr-8">
          <h4 class="text-h6 primary--text">Search Scope</h4>
          <v-radio-group color="primary" row :value="searchScope" @change="onSearchScopeChange($event)" class="mt-0">
            <v-radio label="Catalog" value="catalog" color="primary" />
            <v-radio label="Website" value="website" color="primary" />
          </v-radio-group>
        </v-sheet>
        <v-sheet v-if="showOptions" color="transparent" class="text-xs-left">
          <h4 class="text-h6 primary--text">Search Topic</h4>
          <v-radio-group :value="searchType" color="primary" row @change="$store.dispatch('setSearchType', $event)" class="mt-0">
            <v-radio v-for="type in searchTypeOptions[searchScope]" :key="type.value" color="primary" :label="type.label" :value="type.value" class="text-capitalize" />
          </v-radio-group>
        </v-sheet>
      </v-sheet>
    </v-card-text>
  </v-card>
</template>

<style scoped></style>
