import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    searchInput: null,
    searchResults: null,
    searchScope: 'catalog',
    searchSheetShow: false,
    searchType: 'keyword',
  },
  getters: {},
  actions: {
    setSearchInput({ commit }, value = '') {
      commit('COMMIT_SEARCH_INPUT', value.toLowerCase())
    },
    setSearchScope({ commit }, value = '') {
      commit('COMMIT_SEARCH_SCOPE', value)
    },
    setSearchType({ commit }, value = '') {
      commit('COMMIT_SEARCH_TYPE', value)
    },
    toggleSearchSheet({ commit, state }, show = null) {
      const val = show === null ? !state.searchSheetShow : Boolean(show)
      commit('COMMIT_SEARCH_SHEET_SHOW', val)
    },
  },
  mutations: {
    COMMIT_SEARCH_INPUT(state, val) {
      state.searchInput = val
    },
    COMMIT_SEARCH_SCOPE(state, val) {
      state.searchScope = val
    },
    COMMIT_SEARCH_SHEET_SHOW(state, val) {
      state.searchSheetShow = val
    },
    COMMIT_SEARCH_TYPE(state, val) {
      state.searchType = val
    },
  },
  // modules: {},
})
