<script>
  export default {
    name: 'alert-message',
    props: {
      showAlert: {
        type: Boolean,
        default: () => false,
      },
    },
  }
</script>
<template>
  <v-alert dismissible elevation="4" type="error" :value="showAlert" width="100%" class="alert-message mb-0" :style="{ top: `${$vuetify.application.top}px` }">
    <span class="message-text font-weight-bold"> THE LIBRARY WILL BE CLOSED INDEFINITELY!</span></v-alert
  >
</template>

<style scoped>
  .alert-message {
    width: 100%;
    position: sticky;
    top: 195px;
    z-index: 4;
    left: 0px;
  }
  .message-text {
    animation: blink 1.25s linear 4;
  }
  @keyframes blink {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }
</style>
