<script>
  export default {
    props: {
      breakpoint: {
        //pass in vuetify breakpoints
        type: Object,
        required: true,
      },
      navItems: {
        type: Array,
        required: true,
      },
    },
    data: () => ({
      navItemSelected: null,
      highlightSubNavItem: null,
      subNavExpansionPanel: null,
    }),
    computed: {
      isXSmall() {
        return this.breakpoint?.name === 'xs'
      },
      menuLabelDirection() {
        const dirMap = {
          xs: 'column',
          sm: 'column',
          md: 'column',
          lg: 'row',
          xl: 'row',
        }
        // const dirMap = {
        //   xs: 'row',
        //   sm: 'column',
        //   md: 'column',
        //   lg: 'row',
        //   xl: 'row',
        // }
        return dirMap[this.$vuetify.breakpoint.name] || 'row'
      },
    },
    methods: {
      menuLabelText(text) {
        const clientW = document.body.clientWidth
        return clientW < 400 ? text.split(' ')[0] : text
      },
    },
  }
</script>
<template>
  <v-sheet color="transparent" class="nav-row d-flex align-center justify-space-between">
    <!-- :class="$vuetify.breakpoint.xs && 'flex-column'" -->
    <v-card v-for="navItem in navItems" :key="'navFlex' + navItem.value" flat class="pa-1 rounded">
      <!-- <v-card v-for="navItem in navItems" :key="'navFlex' + navItem.value" flat tile :width="menuLabelDirection === 'row' ? '100%' : ''" class="pa-1 rounded"> -->
      <v-menu
        open-on-click
        open-on-hover
        close-on-click
        :close-on-content-click="breakpoint.mdAndUp ? true : false"
        elevation="0"
        min-width="100%"
        bottom
        nudge-bottom
        offset-y
        transition="scroll-y-transition"
        v-on:input="$event === true ? (navItemSelected = navItem.value) : (navItemSelected = null)"
      >
        <template v-slot:activator="{ on }">
          <v-sheet
            v-on="on"
            class="primary--text d-flex align-center justify-center text-truncate"
            :class="`flex-${menuLabelDirection} ${navItemSelected === navItem.value ? 'primary secondary--text' : 'secondary primary--text'} `"
            height="45px"
            rounded
            width="100%"
            @mouseover="navItemSelected = navItem.value"
          >
            <!-- :class="`flex-${menuLabelDirection} ${navItemSelected === navItem.value ? 'primary secondary--text' : 'secondary primary--text'} `" -->
            <v-icon :left="menuLabelDirection === 'row'" :color="navItemSelected === navItem.value ? 'secondary' : 'primary'">{{ navItem.icon }}</v-icon>
            <font class="font-weight-bold" :class="menuLabelDirection === 'column' ? 'caption' : 'ml-1 '">{{ menuLabelText(navItem.text) }}</font>
          </v-sheet>
        </template>
        <v-card color="secondary" flat rounded width="98%" :class="breakpoint.smAndDown ? 'mt-4' : 'mt-4'" class="subnav-menu-card mx-auto elevation-0">
          <v-card-text class="primary--text subNavPanelCardText">
            <v-scale-transition leave-absolute>
              <v-row justify="space-around" align="start">
                <template v-if="breakpoint.smAndDown">
                  <v-expansion-panels v-model="subNavExpansionPanel" accordion rounded class="primary--text">
                    <v-expansion-panel v-for="subLinks in navItem.subnav" :key="subLinks.text" class="primary--text">
                      <v-expansion-panel-header>
                        <v-sheet color="transparent" width="100%" height="100%" class="d-flex align-center justify-start primary--text">
                          <v-icon color="primary" class="mr-2">{{ subLinks.icon }}</v-icon>
                          {{ subLinks.text }}
                        </v-sheet>
                        <v-spacer></v-spacer>
                        <template v-slot:actions>
                          <v-icon color="primary">$vuetify.icons.expand</v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="secondary-nav-panel-content primary--text secondary text-xs-left px-4 py-2">
                        <ul>
                          <li
                            v-for="link in subLinks.links"
                            transition="fade-transition"
                            :key="link.text + subLinks.text"
                            left
                            @mouseenter="highlightSubNavItem = link.text"
                            @mouseleave="highlightSubNavItem = null"
                            class="font-weight-medium py-1"
                            :style="highlightSubNavItem === link.text ? 'background-color: rgba(0, 0, 102, 0.35);' : ''"
                          >
                            {{ link.text }}
                          </li>
                        </ul>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </template>
                <template v-if="breakpoint.mdAndUp">
                  <template v-for="subLinks in navItem.subnav">
                    <v-col col="12" sm="4" :key="subLinks.text" class="text-xs-left pa-3">
                      <v-sheet color="transparent" class="d-flex align-center pb-2 primary--text">
                        <v-icon color="primary" class="mr-2">{{ subLinks.icon }}</v-icon>
                        <font class="title" :key="subLinks.text + 'title'">{{ subLinks.text }}</font>
                      </v-sheet>
                      <v-divider class="primary--text" light :key="subLinks.text + 'divider'"></v-divider>
                      <template>
                        <div class="text-xs-left mt-1">
                          <ul>
                            <li
                              v-for="link in subLinks.links"
                              transition="fade-transition"
                              :key="link.text + subLinks.text"
                              left
                              @mouseenter="highlightSubNavItem = link.text"
                              class="font-weight-medium"
                              :style="highlightSubNavItem === link.text ? 'background-color: rgba(0, 0, 102, 0.35);' : ''"
                            >
                              {{ link.text }}
                            </li>
                          </ul>
                        </div>
                      </template>
                    </v-col>
                  </template>
                </template>
              </v-row>
            </v-scale-transition>
          </v-card-text>
        </v-card>
      </v-menu>
    </v-card>
  </v-sheet>
</template>

<style scoped>
  .nav-row {
    /* display: flex;
    align-content: center;
    justify-content: space-between; */
    /* flex-direction: column; */
    gap: 1rem;
    width: 100%;
  }

  .nav-row > * {
    flex: 1 1 33.333%;
  }

  .v-menu__content {
    box-shadow: none;
  }

  .secondary-nav-panel-content {
    max-height: 20rem;
    overflow-y: auto;
  }

  /* TESTING BACKGROUND LOGO */
  /* .subNavPanelCardText::before {
  content: ' ';
  background-image: url('@/static/eiplogo_white.svg');
  background-size: cover;
  position: absolute;
  top: 150px;
  right: 0px;
  bottom: 0px;
  left: 150px;
  opacity: 0.05;
  transform: translate(-50%);
} */
</style>
