import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/about/',
    component: () => import(/* webpackChunkName: "about" */ '@/views/about/about-root.vue'),
    children: [
      {
        path: 'board-of-trustees',
        name: 'board-of-trustees',
        component: () => import(/* webpackChunkName: "about" */ '@/views/about/board.vue'),
      },
      {
        name: 'about',
        path: ':anchor?',
        component: () => import(/* webpackChunkName: "about" */ '@/views/about/about.vue'),
      },
    ],
  },
  {
    path: '/adult',
    name: 'adult',
    component: () => import(/* webpackChunkName: "adult" */ '@/views/adult/adult.vue'),
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/home/home.vue'),
  },
  {
    path: '/kids',
    name: 'kids',
    component: () => import(/* webpackChunkName: "kids" */ '@/views/kids/kids.vue'),
  },

  {
    path: '/kids/',
    component: () => import(/* webpackChunkName: "about" */ '@/views/kids/kids-root.vue'),
    children: [
      {
        name: 'kids',
        path: ':anchor?',
        component: () => import(/* webpackChunkName: "about" */ '@/views/kids/kids.vue'),
      },
    ],
  },
  {
    path: '/resources',
    name: 'resources',
    children: [
      {
        path: '/new-material/:category?',
        name: 'new-material',
        component: () => import(/* webpackChunkName: "resources" */ '@/views/resources/new-material.vue'),
      },
    ],
    component: () => import(/* webpackChunkName: "resources" */ '@/views/resources/resources.vue'),
  },
  {
    path: '/teen',
    name: 'teen',
    component: () => import(/* webpackChunkName: "teen" */ '@/views/teen/teen.vue'),
  },
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/home/home.vue'),
  },
  { path: '/:pathMatch(.*)*', component: () => import(/* webpackChunkName: "home" */ '@/views/page-not-found.vue') },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition
    return { x: 0, y: 0 }
  },
})

export default router
