<script>
  import AlertsButton from '@/components/alerts-button.vue'
  import HoursTile from '@/components/hours-tile.vue'
  import NavBreadcrumbs from '@/components/nav-breadcrumbs.vue'
  import NavMain from '@/components/nav-main.vue'
  import tempNavData from '@/static/tempNavData.js'

  const { navHeaderItems, navMainItems } = tempNavData
  export default {
    name: 'header-main',
    components: { AlertsButton, HoursTile, NavBreadcrumbs, NavMain },
    data: () => ({
      navHeaderItems: navHeaderItems,
      navMainItems: navMainItems,
      searchShow: false,
    }),
    computed: {
      alerts() {
        return this?.$store?.state?.alerts || []
      },
      breakpoint() {
        return this.$vuetify.breakpoint
      },
      extensionHeight() {
        //TODO SET REF ON ALERT, ADJUST HEIGHT
        //ALERT MOVED TO HOME
        // let height = this.searchShow ? 250 : 75
        // if (this.alertVisible) height += 75
        const height = this.showBreadcrumbs ? 118 : 70
        return height
      },
      height() {
        // let height = this.$route.name === 'home' ? 120 : 140
        const height = 120
        return height
      },
      showBreadcrumbs() {
        return this.$route.name !== 'index' && this.$route.name !== 'home'
      },
    },
    methods: {
      navClicks(item) {
        console.log(item)
        if (item === 'search') {
          this.$store.dispatch('toggleSearchSheet', !this.$store.state.searchSheetShow)
        }
      },
    },
  }
</script>
<template>
  <v-app-bar app color="primary" :extension-height="extensionHeight" :height="height" class="main-header">
    <v-img
      src="/eiplogo_transparent.gif"
      cover
      height="auto"
      max-width="300px"
      min-width="100px"
      width="100%"
      position="left, top"
      @click="$router.push({ path: '/home' })"
      class="cursor-pointer flex-shrink-1 mb-auto"
    ></v-img>

    <v-spacer></v-spacer>
    <!-- PICKUP HERE, FIX WHERE BREAK POINTS HIDE ICON TEXT -->
    <!-- PICKUP HERE, FIX LOGO SIZING -->
    <v-sheet color="transparent" height="100%" class="d-flex flex-column align-end justify-start">
      <v-sheet color="transparent" class="d-flex align-end justify-end" style="max-height: 60px">
        <!-- <v-toolbar-items class="align-end justify-end" style="max-height: 60px"> -->
        <v-tooltip v-for="navItem in navHeaderItems" :key="navItem.value" color="primary" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              x-large
              :href="navItem.href ? navItem.href : null"
              :icon="breakpoint.name === 'xs'"
              :target="navItem.href ? '_blank' : undefined"
              :text="breakpoint.name !== 'xs'"
              :to="navItem.to ? navItem.to : undefined"
              @click="!navItem.href && navClicks(navItem.value)"
              class="secondary--text"
            >
              <v-icon color="secondary" md :left="breakpoint.name !== 'xs'">{{ navItem.icon }}</v-icon>
              {{ breakpoint.name !== 'xs' ? navItem.text : '' }}
            </v-btn>
          </template>
          <span class="font-weight-bold pa-4">{{ navItem.title }}</span>
        </v-tooltip>
      </v-sheet>

      <!-- </v-toolbar-items> -->
      <v-sheet color="transparent" class="d-flex align-center mt-2">
        <hours-tile />
        <alerts-button v-if="alerts" />
      </v-sheet>
    </v-sheet>

    <template v-slot:extension>
      <v-sheet color="transparent" height="100%" width="100%" class="d-flex flex-column justify-space-between">
        <nav-main :breakpoint="breakpoint" :navItems="navMainItems"></nav-main>
        <nav-breadcrumbs :show="showBreadcrumbs"></nav-breadcrumbs>
      </v-sheet>
    </template>
  </v-app-bar>
</template>

<style scoped>
  .main-header {
    z-index: 99;
  }
  .active-override.v-btn::before {
    background-color: unset;
  }
</style>
