var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app-bar', {
    staticClass: "main-header",
    attrs: {
      "app": "",
      "color": "primary",
      "extension-height": _vm.extensionHeight,
      "height": _vm.height
    },
    scopedSlots: _vm._u([{
      key: "extension",
      fn: function () {
        return [_c('v-sheet', {
          staticClass: "d-flex flex-column justify-space-between",
          attrs: {
            "color": "transparent",
            "height": "100%",
            "width": "100%"
          }
        }, [_c('nav-main', {
          attrs: {
            "breakpoint": _vm.breakpoint,
            "navItems": _vm.navMainItems
          }
        }), _c('nav-breadcrumbs', {
          attrs: {
            "show": _vm.showBreadcrumbs
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('v-img', {
    staticClass: "cursor-pointer flex-shrink-1 mb-auto",
    attrs: {
      "src": "/eiplogo_transparent.gif",
      "cover": "",
      "height": "auto",
      "max-width": "300px",
      "min-width": "100px",
      "width": "100%",
      "position": "left, top"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          path: '/home'
        });
      }
    }
  }), _c('v-spacer'), _c('v-sheet', {
    staticClass: "d-flex flex-column align-end justify-start",
    attrs: {
      "color": "transparent",
      "height": "100%"
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex align-end justify-end",
    staticStyle: {
      "max-height": "60px"
    },
    attrs: {
      "color": "transparent"
    }
  }, _vm._l(_vm.navHeaderItems, function (navItem) {
    return _c('v-tooltip', {
      key: navItem.value,
      attrs: {
        "color": "primary",
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var on = _ref.on,
              attrs = _ref.attrs;
          return [_c('v-btn', _vm._g(_vm._b({
            staticClass: "secondary--text",
            attrs: {
              "x-large": "",
              "href": navItem.href ? navItem.href : null,
              "icon": _vm.breakpoint.name === 'xs',
              "target": navItem.href ? '_blank' : undefined,
              "text": _vm.breakpoint.name !== 'xs',
              "to": navItem.to ? navItem.to : undefined
            },
            on: {
              "click": function ($event) {
                !navItem.href && _vm.navClicks(navItem.value);
              }
            }
          }, 'v-btn', attrs, false), on), [_c('v-icon', {
            attrs: {
              "color": "secondary",
              "md": "",
              "left": _vm.breakpoint.name !== 'xs'
            }
          }, [_vm._v(_vm._s(navItem.icon))]), _vm._v(" " + _vm._s(_vm.breakpoint.name !== 'xs' ? navItem.text : '') + " ")], 1)];
        }
      }], null, true)
    }, [_c('span', {
      staticClass: "font-weight-bold pa-4"
    }, [_vm._v(_vm._s(navItem.title))])]);
  }), 1), _c('v-sheet', {
    staticClass: "d-flex align-center mt-2",
    attrs: {
      "color": "transparent"
    }
  }, [_c('hours-tile'), _vm.alerts ? _c('alerts-button') : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }